import React from 'react'
import "../styles/Footer.css";

const Footer = () => {
  return (
    <div>
        <footer className='footertext'>
            <p>
            © 2022 | Copyright Sha7en.ae. | All rights reserved.
            </p>
        </footer>
      
    </div>
  )
}

export default Footer
